.cards {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media only screen and (max-width: 767px) {
  .cards {
    flex-direction: column;
  }
}
