.floating-title {
  transform: translateY(-50%);
  position: absolute;
  z-index: 20;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
  /* text-shadow: 0 1px 2px rgba(black, .08) */
}
