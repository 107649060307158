.popup {
  display: flex;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(var(--color-pink-100-rgb), 0.3);
  z-index: 100;
  justify-content: center;
  align-items: center;
}
.popup__content {
  width: var(--container);
  background: var(--color-white-background);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  animation: popupOpenClose var(--transition-100);
}
@keyframes popupOpenClose {
  from {
    transform: scale(0.5);
  }
  to {
    transform: scale(1);
  }
}
@media only screen and (max-width: 767px) {
  .popup__content {
    width: 90%;
  }
}
