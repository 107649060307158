.footer {
  display: flex;
  justify-content: space-between;
  gap: 1rem;

  width: 100%;
}
@media only screen and (max-width: 767px) {
  .footer {
    flex-direction: column;
  }
}
