.button {
  transition: var(--transition-200);
  display: inline-block;
  padding: 0.75rem 1.25rem;
  background: var(--color-light-background);
  border: 1px solid var(--color-light-background);
  border-radius: 0;
  color: var(--color-light-text);
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  font-weight: bold;
  position: relative;
}
.button--line {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
}
.button--line span {
  position: relative;
  z-index: 10;
  color: var(--color-white-background);
  background-color: transparent;
  border-color: transparent;
}
.button--line:before {
  content: "";
  position: absolute;
  left: -0.375rem;
  bottom: -0.375rem;
  z-index: 5;
  transition: var(--transition-200);
  display: block;
  width: calc(100% + 0.75rem);
  height: 1px;
  background: var(--color-white-background);
}
.button--line:hover {
  color: var(--color-dark-background);
}
.button--line:hover:before {
  height: calc(100% + 0.75rem);
}
.button--arrow:after {
  content: "\f178";
  font-family: "Font Awesome 5 Pro";
  font-weight: lighter;
  padding-left: 10px;
}
.button--link {
  font-weight: bolder;
  padding: unset;
  background: transparent;
  border: 0px transparent;
  color: var(--color-dark-background);
}
.button--link:hover {
  color: var(--color-light-text);
}
.button--primary {
  background-color: var(--color-primary-background);
  color: var(--color-primary-text);
  border-color: var(--color-primary-background);
}
.button--primary:hover {
  background-color: var(--color-primary-hover);
  border-color: var(--color-primary-hover);
}
.button--primary.button--line {
  background-color: transparent;
  border-color: transparent;
}
.button--primary.button--line span {
  color: var(--color-primary-background);
}
.button--primary.button--line:before {
  background-color: var(--color-primary-background);
}
.button--primary.button--line:hover,
.button--primary.button--line:hover span {
  color: var(--color-primary-text);
}

.button--secondary {
  background-color: var(--color-secondary-background);
  color: var(--color-secondary-text);
  border-color: var(--color-secondary-background);
}
.button--secondary:hover {
  background-color: var(--color-secondary-hover);
  border-color: var(--color-secondary-hover);
}
.button--secondary.button--line {
  background-color: transparent;
  border-color: transparent;
}
.button--secondary.button--line span {
  color: var(--color-secondary-background);
}
.button--secondary.button--line:before {
  background-color: var(--color-secondary-background);
}
.button--secondary.button--line:hover,
.button--secondary.button--line:hover span {
  color: var(--color-secondary-text);
}

.button--white {
  background-color: var(--color-white-background);
  color: var(--color-white-text);
  border-color: var(--color-white-background);
}
.button--white:hover {
  background-color: var(--color-white-hover);
  border-color: var(--color-white-hover);
}
.button--white.button--line {
  background-color: transparent;
  border-color: transparent;
}
.button--white.button--line span {
  color: var(--color-white-background);
}
.button--white.button--line:before {
  background-color: var(--color-white-background);
}
.button--white.button--line:hover,
.button--white.button--line:hover span {
  color: var(--color-white-text);
}

.button--light {
  background-color: var(--color-light-background);
  color: var(--color-light-text);
  border-color: var(--color-light-background);
}
.button--light:hover {
  background-color: var(--color-light-hover);
  border-color: var(--color-light-hover);
}
.button--light.button--line {
  background-color: transparent;
  border-color: transparent;
}
.button--light.button--line span {
  color: var(--color-light-background);
}
.button--light.button--line:before {
  background-color: var(--color-light-background);
}
.button--light.button--line:hover,
.button--light.button--line:hover span {
  color: var(--color-light-text);
}

.button--grey {
  background-color: var(--color-grey-background);
  color: var(--color-grey-text);
  border-color: var(--color-grey-background);
}
.button--grey:hover {
  background-color: var(--color-grey-hover);
}
.button--grey.button--line {
  background-color: transparent;
  border-color: transparent;
}
.button--grey.button--line span {
  color: var(--color-grey-background);
}
.button--grey.button--line:before {
  background-color: var(--color-grey-background);
}
.button--grey.button--line:hover,
.button--grey.button--line:hover span {
  color: var(--color-grey-text);
}

.button--dark {
  background-color: var(--color-dark-background);
  color: var(--color-dark-text);
  border-color: var(--color-dark-background);
}
.button--dark:hover {
  background-color: var(--color-dark-hover);
  border-color: var(--color-dark-hover);
}
.button--dark.button--line {
  background-color: transparent;
  border-color: transparent;
}
.button--dark.button--line span {
  color: var(--color-dark-background);
}
.button--dark.button--line:before {
  background-color: var(--color-dark-background);
}
.button--dark.button--line:hover,
.button--dark.button--line:hover span {
  color: var(--color-dark-text);
}

.button--confirm {
  background-color: var(--color-confirm-background);
  color: var(--color-confirm-text);
  border-color: var(--color-confirm-background);
}
.button--confirm:hover {
  background-color: var(--color-confirm-hover);
  border-color: var(--color-confirm-hover);
}
.button--confirm.button--line {
  background-color: transparent;
  border-color: transparent;
}
.button--confirm.button--line span {
  color: var(--color-confirm-background);
}
.button--confirm.button--line:before {
  background-color: var(--color-confirm-background);
}
.button--confirm.button--line:hover,
.button--confirm.button--line:hover span {
  color: var(--color-confirm-text);
}

.button--warning {
  background-color: var(--color-warning-background);
  color: var(--color-warning-text);
  border-color: var(--color-warning-background);
}
.button--warning:hover {
  background-color: var(--color-warning-hover);
  border-color: var(--color-warning-hover);
}
.button--warning.button--line {
  background-color: transparent;
  border-color: transparent;
}
.button--warning.button--line span {
  color: var(--color-warning-background);
}
.button--warning.button--line:before {
  background-color: var(--color-warning-background);
}
.button--warning.button--line:hover,
.button--warning.button--line:hover span {
  color: var(--color-warning-text);
}

.button--danger {
  background-color: var(--color-danger-background);
  color: var(--color-danger-text);
  border-color: var(--color-danger-background);
}
.button--danger:hover {
  background-color: var(--color-danger-hover);
  border-color: var(--color-danger-hover);
}
.button--danger.button--line {
  background-color: transparent;
  border-color: transparent;
}
.button--danger.button--line span {
  color: var(--color-danger-background);
}
.button--danger.button--line:before {
  background-color: var(--color-danger-background);
}
.button--danger.button--line:hover,
.button--danger.button--line:hover span {
  color: var(--color-danger-text);
}

.button--help {
  background-color: var(--color-help-background);
  color: var(--color-help-text);
  border-color: var(--color-help-background);
}
.button--help:hover {
  background-color: var(--color-help-hover);
  border-color: var(--color-help-hover);
}
.button--help.button--line {
  background-color: transparent;
  border-color: transparent;
}
.button--help.button--line span {
  color: var(--color-help-background);
}
.button--help.button--line:before {
  background-color: var(--color-help-background);
}
.button--help.button--line:hover,
.button--help.button--line:hover span {
  color: var(--color-help-text);
}
