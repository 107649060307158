.image-banner {
  position: relative;
}
.image-banner:after {
  content: "";

  width: 100%;
  height: 100%;

  background: var(--color-pink-300);
  opacity: 0.3;

  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10;
}
