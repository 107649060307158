.banner-container {
  height: calc(100vh - var(--nav-height));
  display: flex;
  justify-content: center;
  align-items: center;
}
.banner-container .banner-circle-container {
  width: calc(90vh - var(--nav-height));
}
.banner-container .banner-circle-container .banner-circle {
  width: 100%;
  padding-top: 100%;
  border-radius: 100000px;
  background: var(--color-white);
  position: relative;
}
.banner-container .banner-circle-container .banner-circle .banner-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  overflow: hidden;
}
.banner-container .banner-circle-container .banner-circle .banner-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 12;
  text-align: center;
}
.banner-container .banner-circle-container .banner-circle .play-button {
  background: var(--color-light-background);
  width: auto;
  height: auto;
  padding: 0.4rem;
  border-radius: var(--radius-rounded);
  position: absolute;
  top: 20%;
  right: 6%;
  z-index: 15;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: var(--transition-200);
}
.banner-container .banner-circle-container .banner-circle .play-button svg {
  font-size: var(--font-size-250);
  font-weight: bolder;
  position: relative;
  top: 1px;
  left: 1px;
}
.banner-container .banner-circle-container .banner-circle .play-button:before {
  --play-button-before-size: 65px;

  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: inline-block;
  background-image: url("/public/images/play-video.png");
  background-size: var(--play-button-before-size) var(--play-button-before-size);
  width: var(--play-button-before-size);
  height: var(--play-button-before-size);
}
.banner-container .banner-circle-container .banner-circle .play-button:hover {
  background: var(--color-light-background);
  cursor: pointer;
}

@media only screen and (max-width: 584px) {
  .banner-container .banner-circle-container {
    width: 80%;
  }
}
