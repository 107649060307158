.card {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 47.5%;
  position: relative;
  margin-bottom: 12.5%;
}
.card:nth-child(even) {
  margin-top: 5%;
}
.card__content {
  display: inline-block;
  text-decoration: none;
  color: var(--color-dark-background);
  position: absolute;
  left: 50%;
  bottom: 0;
  z-index: 10;
  transform: translate(-50%, 35%);
  background: var(--color-white-background);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16);
  padding: 40px 30px;
  width: calc(100% - (30px * 4));
}
.card__content__text {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.card__content__text i {
  margin-left: 7px;
}
@media only screen and (max-width: 767px) {
  .card {
    flex-basis: 90%;
  }
  .card__content {
    position: static;
    transform: none;
    padding: 30px 20px;
    width: calc(100% - (10px * 4));
  }
}
